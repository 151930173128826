import React from "react"
import { graphql } from "gatsby"
import get from 'lodash/get'

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import HeroHeader from "../components/heroheader"
import ArticleTeaser from "../components/articleteaser"


class BlogPage extends React.Component {
  render() {

    const posts = get(this, 'props.data.allNodeArticle.edges')


    return (
      <Layout>
        <SEO title="Tom Howard's Blog" />

        <HeroHeader title="Blog" summary="Some blog posts of my thoughts and updates"/>

          <TeaserList>
              {posts.map(({ node }) => {
                return (
                  <TeaserListItem key={node.path.alias}>
                    <ArticleTeaser article={node} />
                  </TeaserListItem>
                )
              }
              )
            }
          </TeaserList>
          
      </Layout>
    )
  }
}

export default BlogPage

export const pageBlogQuery = graphql`
  query BlogItemsQuery {
    site {
      siteMetadata {
        title
      }
    }

    allNodeArticle(sort: {fields: created, order: DESC}) {
      edges {
        node {
          title
          created(formatString: "dddd Do, MMMM YYYY")
          path {
            alias
          }
          body {
            summary
          }
          relationships {
            field_image {
              localFile {
                publicURL
                childImageSharp {
                  # Specify a fixed image and fragment.
                  # The default width is 400 pixels
                  fixed(width: 700, height: 500) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            } 
            
          }
        }
      }
    }
  }
`


const TeaserList = styled.ul`
  margin: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 60px;
`
const TeaserListItem = styled.li`
  list-style: none;
  flex: 100%;
  margin: 10px;
  background: #F7F8FA;

  h2:last-child {
    margin-bottom: 15px;
  }

  @media(min-width: 600px) {
    flex: 0 0 50%;
  }

  @media(min-width: 740px) {
    flex: 0 0 30%;
  }
`